<template>
  <div class="text-center mt-5 px-5">
    <h2 class="h2 text-center font-weight-bolder my-5">新增管理者</h2>
    <section class="d-flex justify-content-center align-items-center">
      <form class="form">
        <div class="form-group">
          <label for="" class="form-label text-left">Email</label>
          <input
            type="email"
            class="form-control"
            style="width: 20em"
            v-model="user.email"
          />
        </div>
        <div class="form-group mb-5">
          <label for="password" class="form-label text-left">密碼</label>
          <input type="password" class="form-control" v-model="user.password" />
        </div>
        <button
          type="button"
          class="btn btn-block btn-outline-primary"
          @click="addAdmin"
        >
          新增
        </button>
      </form>
    </section>
  </div>
</template>

<script>
import { reactive, inject } from "vue";
import { signUp } from "connection/admin/auth";
export default {
  name: "Admin",
  setup() {
    const mitt = inject("mitt");
    const user = reactive({
      email: "",
      password: "",
    });

    // add
    const addAdmin = async () => {
      try {
        await signUp(user);
        mitt.emit("alert", { state: "success", message: "註冊成功" });
      } catch (error) {
        mitt.emit("alert", { state: "danger", message: error.message });
      }
    };

    return {
      user,
      addAdmin,
    };
  },
};
</script>
